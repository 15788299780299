export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'cliente.update',
      {
        values: {
          idcliente: id,
          nombre: formData.nombre,
          razon_social: formData.razon_social,
          cif: formData.cif,
          idtcliente: formData.idtcliente,
          idtsector: formData.idtsector,
          idcanal_conocimiento: formData.idcanal_conocimiento,
          idagente_comercial: formData.idagente_comercial,
          idcomercial: formData.idcomercial,
          observaciones: formData.observaciones,
          ididioma: formData.ididioma,
          idpais: formData.idpais,
          idclidir_postal: formData.cliente_direccion_postal?.idcliente_direccion || null,
          idclidir_fiscal: formData.cliente_direccion_fiscal?.idcliente_direccion || null,
        },
      }
    )
  },
}
